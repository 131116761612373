import React, { useState, useEffect } from 'react'
import { TypeformEmbed } from './TypeformEmbed'
import { UserType } from 'queries/types/user'
import { useValidateUserToken } from '@hooks/useValidateUserToken'
import { useTranslation } from 'react-i18next'
import { emptyUser } from '@lib/constants'
import * as typeformEmbed from '@typeform/embed'
import { getFormIdFromTypeformLink } from '@lib/typeformUtils'

import axios from 'axios'

interface Props {
  fields: {
    typeformLink: string
  }
}

const ApplicationBlock = ({ fields: { typeformLink } }: Props) => {
  const { i18n } = useTranslation()
  const { user } = useValidateUserToken(i18n.language)
  const [adjustedTypeformUrl, setAdjustedTypeformUrl] = useState(typeformLink)

  const submitApplication = async (event: typeformEmbed.OnSubmitEvent) => {
    let validUser: UserType = user === null ? emptyUser : user
    const formId = getFormIdFromTypeformLink(typeformLink)
    const data = {
      form_id: formId,
      response_id: event.response_id,
      token: validUser.token,
    }
    await axios(`/api/application/submit`, { method: 'post', data: data })
  }

  useEffect(() => {
    const urlPath = new URL(typeformLink)
    let validUser: UserType = user === null ? emptyUser : user

    setAdjustedTypeformUrl(`
    ${urlPath.origin}${urlPath.pathname}#email=${validUser.email}&first_name=${validUser.first_name}&last_name=${validUser.last_name}&organization=${validUser.organization_name}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeformLink, user])

  return (
    <TypeformEmbed typeformLink={adjustedTypeformUrl} height={500} onSubmit={submitApplication} />
  )
}

export { ApplicationBlock }
