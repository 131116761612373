import React from 'react'
import classNames from 'classnames'

import style from '@styles/ProConCard.module.scss'

interface Props {
  fields: {
    title: string
    pros: string[]
    cons: string[]
  }
}

const ProConCard = ({ fields: { title, pros, cons } }: Props) => {
  return (
    <div className={classNames(style.container)}>
      <div className={style.title}>{title}</div>
      <div className={style.contentContainer}>
        <ul className={classNames([style.list, style.proList])}>
          {pros.map(pro => (
            <li>{pro}</li>
          ))}
        </ul>
        <ul className={classNames([style.list, style.conList])}>
          {cons.map(con => (
            <li>{con}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export { ProConCard }
