import { UserType } from 'queries/types/user'

const emptyUser: UserType = {
  token: '',
  email: '',
  first_name: '',
  last_name: '',
  organization_name: '',
}

export { emptyUser }
