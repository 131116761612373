/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import Link from 'next/link'
import classNames from 'classnames'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'

import '@lib/i18n/config'
import style from '@styles/LeftNavigation.module.scss'
import { AccountIcon } from '@components/icons/AccountIcon'
import { InfoIcon } from '@components/icons/InfoIcon'
import { MagnifierIcon } from '@components/icons/MagnifierIcon'
import { LeftNavigationSection } from '@components/LeftNavigationSection'
import { SectionType, TrainingType } from '@contentful/types'
import { getLocalizedPath } from '@lib/i18n'

interface Props {
  training: TrainingType
  completedPageIds?: string[]
  currentSection: SectionType | null
  currentPageId: string
  currentPath: string
  locale: string
  onLanguageChange: (locale: string) => void
}

const LeftNavigation = ({
  completedPageIds,
  currentSection,
  currentPageId,
  currentPath,
  locale,
  training,
  onLanguageChange,
}: Props) => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(locale)
  }, [i18n, locale])

  const accountPath = getLocalizedPath(locale, '/account')
  const onAccountPage = /account/.test(currentPath)

  const faqPath = get(training, 'fields.faqPath')
  const onFaqPage = faqPath && currentPath === faqPath.fields.path

  const keyTermsPath = get(training, 'fields.keyTermsPath') as any
  const onKeyTermsPath = keyTermsPath && currentPath === keyTermsPath.fields.path

  return (
    <div className={style.container}>
      <div className={style.header}>
        <img className={style.logo} src="/images/vroom-logo.svg" alt="Vroom" />
        {/* The language switcher is currently hidden. For now, we are only offering the toolkit in english */}
        {/* <LanguageSwitcher locale={locale} onLanguageChange={onLanguageChange} /> */}
      </div>
      <div className={style.sectionList}>
        {training.fields.sections.map((section, index) => (
          <LeftNavigationSection
            key={index}
            section={section}
            completedSectionLabel={training.fields.completedSectionLabel}
            currentSection={currentSection}
            currentPageId={currentPageId}
            completedPageIds={completedPageIds}
          />
        ))}
      </div>
      <div className={style.nonTrainingPageListContainer}>
        <div className={style.nonTrainingPageList}>
          <div className={classNames(style.pageTitle, { [style.currentPage]: onAccountPage })}>
            <AccountIcon className={style.pageIcon} />
            <Link href={accountPath} className={style.pageLink}>
              {t('account.pageTitle')}
            </Link>
          </div>
          {faqPath && (
            <div className={classNames(style.pageTitle, { [style.currentPage]: onFaqPage })}>
              <InfoIcon className={style.pageIcon} />
              <Link href={`${faqPath.fields.path}?restored=1`} className={style.pageLink}>
                {faqPath.fields.name}
              </Link>
            </div>
          )}
          {keyTermsPath && (
            <div className={classNames(style.pageTitle, { [style.currentPage]: onKeyTermsPath })}>
              <MagnifierIcon className={style.pageIcon} />
              <Link href={`${keyTermsPath.fields.path}?restored=1`} className={style.pageLink}>
                {keyTermsPath.fields.name}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export { LeftNavigation }
