import React from 'react'
import classNames from 'classnames'
import { get } from 'lodash'

import style from '@styles/ImageBlock.module.scss'
import { MediaType } from '@contentful/types'

interface Props {
  fields: {
    image: MediaType
    layout?: string
    spacing?: string
  }
}

const ImageBlock = ({ fields: { image, layout, spacing } }: Props) => {
  const description = get(image, 'fields.description')
  const url = get(image, 'fields.file.url')

  const layoutClass = (layout => {
    switch (layout) {
      case 'Center':
        return style.center
      case 'Footer':
        return style.footer
      default:
        return null
    }
  })(layout)

  const spacingClass = (spacing => {
    switch (spacing) {
      case 'Top':
        return style.topSpacing
      case 'Half Top':
        return style.halfTopSpacing
      case 'None':
        return style.noSpacing
      default:
        return null
    }
  })(spacing)

  return (
    <div className={classNames(layoutClass, spacingClass)}>
      <img className={style.image} src={url} alt={description} />
    </div>
  )
}

export { ImageBlock }
