import React, { useState } from 'react'
import Markdown from 'react-markdown'

import style from '@styles/ExpandableContentBlock.module.scss'
import { ExpandableContentBlockType } from '@contentful/types'

interface Props {
  block: ExpandableContentBlockType
  expandButtonLabel: string
  collapseButtonLabel: string
}

const ExpandableContentBlock = ({
  block: {
    fields: { title, content },
  },
  expandButtonLabel,
  collapseButtonLabel,
}: Props) => {
  const [expanded, setExpanded] = useState(false)

  const buttonLabel = expanded ? collapseButtonLabel : expandButtonLabel
  const buttonIcon = expanded ? '/icons/minus.svg' : '/icons/plus.svg'

  const toggleExpanded = () => setExpanded(!expanded)

  return (
    <div className={style.container}>
      <div className={style.title}>{title}</div>
      <button className={style.button} aria-label={buttonLabel} onClick={toggleExpanded}>
        <img src={buttonIcon} alt={buttonLabel} />
      </button>
      {expanded && <Markdown className={style.content} linkTarget="_blank" source={content} />}
    </div>
  )
}

export { ExpandableContentBlock }
