import React from 'react'
import Markdown from 'react-markdown'

import style from '@styles/ExpandableContentListBlock.module.scss'
import { BasicHeader } from '@components/BasicHeader'
import { ExpandableContentBlock } from '@components/ExpandableContentBlock'
import { ExpandableContentBlockType } from '@contentful/types'

interface Props {
  fields: {
    title: string
    subtitle: string
    blocks: ExpandableContentBlockType[]
    expandButtonLabel: string
    collapseButtonLabel: string
    footer?: string
  }
}

const ExpandableContentListBlock = ({
  fields: { title, subtitle, blocks, expandButtonLabel, collapseButtonLabel, footer },
}: Props) => (
  <div className={style.container}>
    <BasicHeader title={title} subtitle={subtitle} />
    <div className={style.blockList}>
      {blocks.map(block => (
        <ExpandableContentBlock
          key={block.sys.id}
          block={block}
          expandButtonLabel={expandButtonLabel}
          collapseButtonLabel={collapseButtonLabel}
        />
      ))}
    </div>
    {footer && <Markdown className={style.footer} linkTarget="_blank" source={footer} />}
  </div>
)

export { ExpandableContentListBlock }
