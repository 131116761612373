import React from 'react'
import classNames from 'classnames'
import Markdown from 'react-markdown'

import style from '@styles/PdfDownloadBlock.module.scss'

interface DownloadableAssetProps {
  fields: {
    file: {
      url: string
    }
  }
}

interface Props {
  fields: {
    backgroundColor: string
    content: string
    downloadablePdf: DownloadableAssetProps
    downloadLinkText: string
    illustrationLocation: string
    title: string
  }
}

const PdfDownloadBlock = ({
  fields: {
    backgroundColor,
    content,
    downloadablePdf,
    downloadLinkText,
    illustrationLocation,
    title,
  },
}: Props) => {
  const backgroundColorClass = (color => {
    switch (color) {
      case 'Dark':
        return style.containerDark
      case 'Light':
        return style.containerLight
      default:
        return null
    }
  })(backgroundColor)

  const downloadUrl = downloadablePdf?.fields?.file?.url

  return (
    <div className={classNames(style.container, backgroundColorClass)}>
      <div className={style.contentContainer}>
        <div className={style.title}>{title}</div>
        <Markdown
          className={classNames(style.content)}
          linkTarget="_blank"
          source={content}
          escapeHtml={false}
        />
        <a className={style.link} href={downloadUrl} download target="_blank" rel="noreferrer">
          {downloadLinkText}
        </a>
        {illustrationLocation === 'Left' && (
          <>
            <img
              className={style.leftImage}
              src="/images/left-peeking-child.svg"
              alt="Pdf download left peeking child illustration"
              aria-hidden
            />
            <img
              className={style.leftSparkles}
              src="/images/sparkles-1.svg"
              alt="Pdf download background"
              aria-hidden
            />
            <img
              className={style.rightSparkles}
              src="/images/sparkles-2.svg"
              alt="Pdf download background"
              aria-hidden
            />
          </>
        )}
        {illustrationLocation === 'Right' && (
          <>
            <img
              className={style.rightImage}
              src="/images/right-peeking-child.svg"
              alt="Pdf download right peeking child illustration"
              aria-hidden
            />
            <img
              className={style.leftButterflies}
              src="/images/butterflies-1.svg"
              alt="Pdf download background"
              aria-hidden
            />
            <img
              className={style.rightButterflies}
              src="/images/butterflies-2.svg"
              alt="Pdf download background"
              aria-hidden
            />
          </>
        )}
      </div>
    </div>
  )
}

export { PdfDownloadBlock }
