import React from 'react'

import style from '@styles/Link.module.scss'

export interface LinkType {
  fields: {
    name: string
    path: string
  }
}

const Link = ({ fields: { name, path } }: LinkType) => (
  <a className={style.link} href={path}>
    {name}
  </a>
)

export { Link }
