import React from 'react'
import classNames from 'classnames'
import { get } from 'lodash'

import style from '@styles/TextAndImageBlock.module.scss'
import { BlockType, MediaType } from '@contentful/types'
import { TextBlock } from '@components/TextBlock'

interface Props {
  fields: {
    text: BlockType
    image: MediaType
    imagePosition: string
    spacing?: string
  }
}

const TextAndImageBlock = ({ fields: { text, image, imagePosition, spacing } }: Props) => {
  const imageUrl = get(image, 'fields.file.url')
  const imageDescription = get(image, 'fields.description')

  const imagePositionClass = (imagePosition => {
    switch (imagePosition) {
      case 'Left':
        return style.leftImage
      default:
        return style.rightImage
    }
  })(imagePosition)

  const spacingClass = (spacing => {
    switch (spacing) {
      case 'Extra Top':
        return style.extraTopSpacing
      case 'Extra Bottom':
        return style.extraBottomSpacing
      case 'Extra Top and Bottom':
        return style.extraTopAndBottomSpacing
      default:
        return null
    }
  })(spacing)

  return (
    <div className={classNames(style.container, imagePositionClass, spacingClass)}>
      <div className={style.text}>
        <TextBlock fields={text.fields} />
      </div>
      <div className={style.image}>
        <img src={imageUrl} alt={imageDescription} />
      </div>
    </div>
  )
}

export { TextAndImageBlock }
