import React from 'react'

import { ApplicationBlock } from './ApplicationBlock'
import { CalloutBlock } from '@components/CalloutBlock'
import { ContentBlock } from '@components/ContentBlock'
import { CtaBlock } from '@components/CtaBlock'
import { EmbeddedContent } from '@components/EmbeddedContent'
import { ExpandableContentListBlock } from '@components/ExpandableContentListBlock'
import { FlowBlock } from '@components/FlowBlock'
import { GrayBox } from '@components/GrayBox'
import { HeaderBlock } from '@components/HeaderBlock'
import { ImageBlock } from '@components/ImageBlock'
import { KeyTermsListBlock } from '@components/KeyTermsListBlock'
import { PdfDownloadBlock } from '@components/PdfDownloadBlock'
import { PhotoCard } from '@components/PhotoCard'
import { ProConCard } from '@components/ProConCard'
import { Quiz } from '@components/Quiz'
import { ReflectionBlock } from '@components/ReflectionBlock'
import { TextAndImageBlock } from '@components/TextAndImageBlock'
import { TextBlock } from '@components/TextBlock'
import { TextCard } from '@components/TextCard'
import { SectionType } from '@contentful/types'

const UnknownBlock = (props: any) => (
  <div>
    <p>Unknown Block</p>
    <pre>{JSON.stringify(props, null, 2)}</pre>
  </div>
)

const BlockComponentMap = {
  applicationBlock: ApplicationBlock,
  calloutBlock: CalloutBlock,
  contentBlock: ContentBlock,
  ctaBlock: CtaBlock,
  embeddedContent: EmbeddedContent,
  expandableContentListBlock: ExpandableContentListBlock,
  flowBlock: FlowBlock,
  grayBox: GrayBox,
  headerBlock: HeaderBlock,
  imageBlock: ImageBlock,
  keyTermsList: KeyTermsListBlock,
  pdfDownload: PdfDownloadBlock,
  photoCard: PhotoCard,
  proConCard: ProConCard,
  quiz: Quiz,
  reflectionBlock: ReflectionBlock,
  textAndImageBlock: TextAndImageBlock,
  textBlock: TextBlock,
  textCard: TextCard,
  unknownBlock: UnknownBlock,
} as const

interface Props {
  type: keyof typeof BlockComponentMap
  fields: any
  section: SectionType | null
  nextPageUrl?: string
  onPageCompleted?: () => void
  layout?: string
}

const Block = ({ type, fields, section, nextPageUrl, onPageCompleted, layout }: Props) => {
  const BlockComponent = BlockComponentMap[type] || UnknownBlock

  return (
    <BlockComponent
      fields={fields}
      section={section}
      nextPageUrl={nextPageUrl}
      onPageCompleted={onPageCompleted}
      pageLayout={layout}
    />
  )
}

export { Block }
