import React from 'react'

import style from '@styles/KeyTermsListBlock.module.scss'

import { KeyTerm } from '@components/KeyTerm'

interface KeyTermProps {
  fields: {
    term: string
    definition: string
    slug: string
  }
  sys: {
    id: string
  }
}

interface Props {
  fields: {
    title: string
    keyTerms: KeyTermProps[]
  }
}

const KeyTermsListBlock = ({ fields: { title, keyTerms } }: Props) => (
  <div className={style.container}>
    <h1 className={style.title}>{title}</h1>
    <table className={style.list}>
      <thead>
        {/* This hidden table row is here for accessibility for screen readers */}
        <tr className={style.headerRow}>
          <th>Term</th>
          <th>Definition</th>
        </tr>
      </thead>

      <tbody>
        {keyTerms.map(keyTerm => (
          <KeyTerm
            key={keyTerm.sys.id}
            term={keyTerm.fields.term}
            definition={keyTerm.fields.definition}
            slug={keyTerm.fields.slug}
          />
        ))}
      </tbody>
    </table>
  </div>
)

export { KeyTermsListBlock }
