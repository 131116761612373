import React from 'react'

import style from '@styles/QuizIllustrations.module.scss'
import classNames from 'classnames'

interface QuizIllustrationsProps {
  inline: boolean
}

const QuizIllustrations = ({ inline }: QuizIllustrationsProps) => (
  <>
    <img
      className={classNames(style.topLeftImage)}
      src="/images/quiz-shapes.svg"
      alt="Quiz shapes"
      aria-hidden
    />
    {!inline && (
      <img
        className={classNames(style.topRightImage)}
        src="/images/quiz-illustration.svg"
        alt="Quiz"
        aria-hidden
      />
    )}
    <img
      className={classNames(inline ? style.bottomLeftImageLow : style.bottomLeftImage)}
      src="/images/quiz-dots.svg"
      alt="Quiz dots"
      aria-hidden
    />
  </>
)

export { QuizIllustrations }
