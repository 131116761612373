import React from 'react'

interface Props {
  className?: any
}

const MagnifierIcon = ({ className }: Props) => (
  <svg
    className={className}
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.958 13.292a4.333 4.333 0 1 0 0-8.667 4.333 4.333 0 0 0 0 8.667Z"
      fill="#fff"
      stroke="currentColor"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m17.376 14.375-2.357-2.356"
      stroke="currentColor"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 2.5V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)

export { MagnifierIcon }
