import { PageType, SectionType } from '@contentful/types'

const findSectionIndexForPage = (pageId: string, sectionList: SectionType[]) =>
  sectionList.findIndex(section => !!section?.fields.pages.find(page => page.sys.id === pageId))

const isAhead = (pageId: string, otherPageId: string | undefined, sectionList: SectionType[]) => {
  if (pageId === otherPageId) return false

  if (!otherPageId) return true

  const pageSectionIndex = findSectionIndexForPage(pageId, sectionList)
  const otherSectionIndex = findSectionIndexForPage(otherPageId, sectionList)

  const sectionDiff = pageSectionIndex - otherSectionIndex

  if (sectionDiff > 0) return true
  if (sectionDiff < 0) return false

  const pageList = sectionList[pageSectionIndex]?.fields.pages

  if (!pageList) {
    throw Error(`No section for page ${pageId}`)
  }

  const pageIndex = pageList.findIndex(page => page.sys.id === pageId)
  const otherPageIndex = pageList.findIndex(page => page.sys.id === otherPageId)

  if (pageIndex > otherPageIndex) return true

  return false
}

const getNextPage = (pageId: string, sectionList: SectionType[]): PageType | null => {
  const pageSectionIndex = findSectionIndexForPage(pageId, sectionList)
  if (pageSectionIndex === -1) {
    return null
  }

  const section = sectionList[pageSectionIndex]

  const pageIndex = section.fields.pages.findIndex(sectionPage => pageId === sectionPage.sys.id)

  if (pageIndex + 1 < section.fields.pages.length) {
    return section.fields.pages[pageIndex + 1]
  }
  if (pageSectionIndex + 1 < sectionList.length) {
    return sectionList[pageSectionIndex + 1].fields.pages[0]
  }
  return null
}

const getNextPageUrl = (currentPageId: string, sectionList: SectionType[]) => {
  const nextPage = getNextPage(currentPageId, sectionList)
  return nextPage?.fields.path.fields.path
}

const getNextPageTitle = (currentPageId: string, sectionList: SectionType[]) => {
  const nextPage = getNextPage(currentPageId, sectionList)
  return nextPage?.fields.title
}

const getNewSectionLabel = (currentPageId: string, sectionList: SectionType[]) => {
  const pageSectionIndex = sectionList.findIndex(
    section => !!section.fields.pages.find(sectionPage => currentPageId === sectionPage.sys.id)
  )

  if (pageSectionIndex === -1) {
    return null
  }

  const section = sectionList[pageSectionIndex]
  const pageIndex = section.fields.pages.findIndex(
    sectionPage => currentPageId === sectionPage.sys.id
  )

  if (pageIndex !== section.fields.pages.length - 1) return null

  return sectionList[pageSectionIndex + 1]?.fields.title
}

const getPageSection = (pageId: string, sectionList: SectionType[]) => {
  for (const section of sectionList) {
    for (const page of section.fields.pages) {
      if (page.sys.id === pageId) {
        return section
      }
    }
  }

  return null
}

export { isAhead, getPageSection, getNewSectionLabel, getNextPageUrl, getNextPageTitle }
