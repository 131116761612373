import React from 'react'

import style from '@styles/KeyTerm.module.scss'

interface Props {
  term: string
  definition: string
  slug: string
}

const KeyTerm = ({ term, definition, slug }: Props) => {
  return (
    <tr className={style.container} id={slug}>
      <td className={style.term}>{term}</td>
      <td className={style.description}>{definition}</td>
    </tr>
  )
}

export { KeyTerm }
