import React from 'react'

import styles from '@styles/CtaBlock.module.scss'
import { LinkButton } from './Button'
import { PathType } from '@contentful/types'

interface Props {
  fields: {
    primaryLabel: string
    primaryLink: PathType
    secondaryLabel: string
    secondaryLink: PathType
  }
}

const CtaBlock = ({
  fields: { primaryLabel, primaryLink, secondaryLabel, secondaryLink },
}: Props) => (
  <div className={styles.container}>
    <LinkButton href={primaryLink.fields.path}>{primaryLabel}</LinkButton>
    <LinkButton href={secondaryLink.fields.path} color="secondary">
      {secondaryLabel}
    </LinkButton>
  </div>
)

export { CtaBlock }
