import React from 'react'
import classNames from 'classnames'
import { get } from 'lodash'

import style from '@styles/GrayBox.module.scss'
import { Block } from '@components/Block'
import { BlockType, SectionType } from '@contentful/types'

interface Props {
  fields: {
    title: string
    blocks: BlockType[]
    layout: string
    spacing: string
  }
  section: SectionType
}

const GrayBox = ({ fields: { title, blocks, layout, spacing }, section }: Props) => {
  const getContentClass = (type: string) => {
    switch (type) {
      case 'textBlock':
        return style.contentText
      case 'imageBlock':
        return style.contentImage
      case 'photoCard':
        return style.contentPhotoCard
      default:
        return ''
    }
  }

  const layoutClass = (layout => {
    switch (layout) {
      case 'Side by Side':
        return style.contentSideBySide
      default:
        return ''
    }
  })(layout)

  const spacingClass = (spacing => {
    switch (spacing) {
      case 'Extra Top':
        return style.containerExtraTopSpacing
      case 'Extra Bottom':
        return style.containerExtraBottomSpacing
      case 'Extra Top and Bottom':
        return style.containerExtraTopAndBottomSpacing
      case 'No Bottom':
        return style.containerNoBottomSpacing
      default:
        return ''
    }
  })(spacing)

  return (
    <div className={classNames(style.container, spacingClass)}>
      <div className={style.content}>
        <h3 className={style.title}>{title}</h3>
        <div className={layoutClass}>
          {blocks.map((block: BlockType) => {
            const id = get(block, 'sys.id')
            const type = get(block, 'sys.contentType.sys.id')
            const contentClass = getContentClass(type)

            return (
              <div key={id} className={contentClass}>
                <Block type={type as any} fields={block.fields} section={section} />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export { GrayBox }
