import { useInView } from 'hooks/useInView'
import React from 'react'

interface PageCompletedTriggerProps {
  onCompleted: () => unknown
}
const PageCompletedTrigger = ({ onCompleted }: PageCompletedTriggerProps) => {
  const ref = useInView(onCompleted, true)

  return <span ref={ref} />
}

export { PageCompletedTrigger }
