const OTHER_LOCALES = ['es']

const getLocaleFromPath = (path: string) => {
  // Root locale path
  for (const locale of OTHER_LOCALES) {
    if (path === `/${locale}`) {
      return locale
    }
  }

  // Non-root locale path
  const localeRegExp = new RegExp(`^/(${OTHER_LOCALES.join('|')})/`)
  const matches = localeRegExp.exec(path)

  if (matches === null) {
    return 'en-US'
  }

  return matches[1]
}

const getLocalizedPath = (locale: string, path: string) => {
  if (locale === 'en-US') return path

  return `/${locale}${path}`
}

export { getLocaleFromPath, getLocalizedPath }
