import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/dist/client/router'
import { TypeformEmbed } from './TypeformEmbed'
import { UserType } from 'queries/types/user'
import { useValidateUserToken } from '@hooks/useValidateUserToken'
import { useTranslation } from 'react-i18next'
import * as typeformEmbed from '@typeform/embed'
import axios from 'axios'
import { emptyUser } from '@lib/constants'
import { getFormIdFromTypeformLink } from '@lib/typeformUtils'
import { QuizIllustrations } from './QuizIllustrations'
import classNames from 'classnames'

import style from '@styles/Quiz.module.scss'

interface Props {
  fields: {
    typeformLink: string
    height: number
    reportingType: string
  }
  nextPageUrl: string
  onPageCompleted: () => void
  pageLayout?: string
}

const Quiz = ({
  fields: { typeformLink, height, reportingType },
  nextPageUrl,
  onPageCompleted,
  pageLayout,
}: Props) => {
  const router = useRouter()
  const { i18n } = useTranslation()
  const { user } = useValidateUserToken(i18n.language)
  const [adjustedTypeformUrl, setAdjustedTypeformUrl] = useState(typeformLink)
  const quizLayout = 'Quiz'
  const isInlineQuiz = pageLayout !== quizLayout
  const handleFormSubmit = async (event: typeformEmbed.OnSubmitEvent) => {
    let validUser: UserType = user === null ? emptyUser : user

    const id = getFormIdFromTypeformLink(typeformLink)
    if (!id) return

    const data = {
      form_id: id,
      response_id: event.response_id,
      token: validUser.token,
    }
    if (reportingType === 'score') await axios(`/api/quiz/submit`, { method: 'post', data })

    onPageCompleted()

    setTimeout(async () => {
      const res = await axios.get(
        `/api/typeform/auto-advance?response_id=${event.response_id}&form_id=${id}`
      )
      if (res.data.autoAdvance) router.push(nextPageUrl)
    }, 6000)
  }

  useEffect(() => {
    const urlPath = new URL(typeformLink)
    let validUser: UserType = user === null ? emptyUser : user

    setAdjustedTypeformUrl(`
    ${urlPath.origin}${urlPath.pathname}#email=${validUser.email}&first_name=${validUser.first_name}&last_name=${validUser.last_name}&organization=${validUser.organization_name}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeformLink, user])

  return (
    <div className={classNames(style.quizContainer)}>
      <TypeformEmbed
        typeformLink={adjustedTypeformUrl}
        height={height}
        onSubmit={handleFormSubmit}
      />
      {isInlineQuiz && <QuizIllustrations inline />}
    </div>
  )
}

export { Quiz }
