import React from 'react'
import Markdown from 'react-markdown'
import classNames from 'classnames'
import { get } from 'lodash'

import style from '@styles/HeaderBlock.module.scss'
import { MediaType, SectionType } from '@contentful/types'

interface Props {
  fields: {
    title: string
    subtitle?: string
    description?: string
    sectionDisplay?: any
    layout?: string
    spacing?: string
    image?: MediaType
  }
  section: SectionType
}

const HeaderBlock = ({
  fields: { title, subtitle, description, sectionDisplay, layout, spacing, image },
  section,
}: Props) => {
  const spacingClass = (spacing => {
    switch (spacing) {
      case 'Half Bottom':
        return style.containerHalfBottomSpacing
      default:
        return null
    }
  })(spacing)

  const sectionIndexLayout = 'Section Index'
  const isSectionIndex = layout === sectionIndexLayout
  const sectionTitle = sectionDisplay ?? (isSectionIndex && get(section, 'fields.title'))

  const layoutClass = (layout => {
    switch (layout) {
      case 'Quiz':
        return style.containerQuiz
      case sectionIndexLayout:
        return style.containerSectionIndex
      default:
        return null
    }
  })(layout)

  const sectionColor = get(section, 'fields.color')
  const titleColorClass = (color => {
    switch (color) {
      case 'Blue':
        return style.titleBlue
      case 'Orange':
        return style.titleOrange
      case 'Purple':
        return style.titlePurple
      case 'Teal':
        return style.titleTeal
      default:
        return null
    }
  })(sectionColor)

  const containerStyles: React.CSSProperties = image
    ? { backgroundImage: `url(${get(image, 'fields.fileurl')})` }
    : {}

  return (
    <div
      className={classNames(style.container, layoutClass, spacingClass)}
      style={containerStyles}
      data-testid="header-block"
    >
      <div className={style.content}>
        {sectionTitle && <div className={style.sectionTitle}>{sectionTitle}</div>}
        <h1 className={classNames(style.title, titleColorClass)}>{title}</h1>
        {subtitle && (
          <Markdown
            className={style.subtitle}
            linkTarget="_blank"
            source={subtitle}
            escapeHtml={false}
          />
        )}
        {description && (
          <Markdown
            className={style.description}
            linkTarget="_blank"
            source={description}
            escapeHtml={false}
          />
        )}
      </div>
    </div>
  )
}

export { HeaderBlock }
