/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import NoSSR from 'react-no-ssr'
import classNames from 'classnames'
import { every, get } from 'lodash'

import style from '@styles/LeftNavigationSection.module.scss'
import { PageType, SectionType } from '@contentful/types'
import Link from 'next/link'

const getPagePath = (page: PageType) => {
  const path = get(page, 'fields.path.fields.path')
  return `${path}?restored=1`
}

const isSectionCompleted = (section: SectionType, completedPageIds: string[]) =>
  every(section.fields.pages, page => completedPageIds?.includes(page.sys.id)) || false

interface SectionProps {
  section: SectionType
  completedPageIds?: string[]
  completedSectionLabel: string
  currentSection?: SectionType | null
  currentPageId: string
}

const LeftNavigationSection = ({
  section,
  completedPageIds,
  completedSectionLabel,
  currentSection,
  currentPageId,
}: SectionProps) => {
  const sidebarTitle = get(section, 'fields.sidebarTitle')
  const icon = get(section, 'fields.icon')

  const iconUrl = get(icon, 'fields.file.url')
  const iconDescription = get(icon, 'fields.description')

  const iconRef = useRef<HTMLImageElement>(null)

  const sectionId = get(section, 'sys.id')
  const currentSectionId = get(currentSection, 'sys.id')
  const isCurrentSection = sectionId === currentSectionId

  const completed = completedPageIds && isSectionCompleted(section, completedPageIds)

  // Inline SVG for icon image
  useEffect(() => {
    if (!iconUrl || !(window && window.fetch)) {
      return
    }

    fetch(iconUrl)
      .then(response => response.text())
      .then(text => {
        const iconImg = iconRef.current
        if (iconImg === null) return

        const parser = new DOMParser()
        const xmlDoc = parser.parseFromString(text, 'text/xml')
        const svg = xmlDoc.getElementsByTagName('svg')[0]

        iconImg.parentNode?.replaceChild(svg, iconImg)
      })
  }, [iconUrl])

  const pages: SectionType['fields']['pages'] = get(section, 'fields.pages')
  const firstPage = pages[0]
  const firstPagePath = getPagePath(firstPage)

  const renderSectionPages = (currentPageId: string) => {
    return (
      <NoSSR>
        <ul className={style.pageList}>
          {pages.map(page => {
            const pageTitle = get(page, 'fields.title')
            const pagePath = getPagePath(page)

            const pageId = get(page, 'sys.id')
            const isCurrentPage = pageId === currentPageId

            const isCompletedPage = completedPageIds?.includes(pageId)

            return (
              <li key={pageId} className={style.page} data-testid="nav-section-page">
                <Link
                  href={pagePath}
                  className={classNames(style.pageLink, {
                    [style.currentPageLink]: isCurrentPage,
                    [style.completedPageLink]: !isCurrentPage && isCompletedPage,
                  })}
                >
                  {pageTitle}
                </Link>
              </li>
            )
          })}
        </ul>
      </NoSSR>
    )
  }

  return (
    <div className={style.section}>
      <div
        className={classNames(style.sectionTitle, {
          [style.currentSectionTitle]: isCurrentSection,
        })}
      >
        <img src={iconUrl} alt={iconDescription} ref={iconRef} aria-hidden />
        <Link href={firstPagePath} className={style.sectionLink}>
          {sidebarTitle}
        </Link>
        {completed && <img src="/icons/checkmark-circle.svg" alt={completedSectionLabel} />}
      </div>
      {isCurrentSection && pages.length > 1 && renderSectionPages(currentPageId)}
    </div>
  )
}

export { LeftNavigationSection }
