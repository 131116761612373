import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Markdown from 'react-markdown'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { debounce } from 'lodash'

import { useValidateUserToken } from '@hooks/useValidateUserToken'
import { emptyUser } from '@lib/constants'
import { UserType } from 'queries/types/user'

import style from '@styles/ReflectionBlock.module.scss'
import classNames from 'classnames'

interface Props {
  fields: {
    databaseId: string
    title: string
    subtitle?: string
    placeholder: string
  }
}

const DEBOUNCE_SAVE_DELAY_MS = 1500

const ReflectionBlock = ({ fields: { databaseId, title, subtitle, placeholder } }: Props) => {
  const { i18n } = useTranslation()
  const { user } = useValidateUserToken(i18n.language)
  const { register, setValue } = useForm()
  const [showSavedIndicator, setShowSavedIndicator] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let validUser: UserType = user === null ? emptyUser : user

    async function fetchReflectionResponse() {
      if (!databaseId || !validUser.token) return

      setLoading(true)
      const apiResponse = await axios(
        `/api/reflection/get?databaseId=${databaseId}&token=${validUser.token}`
      )
      setValue('responseText', apiResponse?.data?.response_text)
      setLoading(false)
    }

    fetchReflectionResponse()
  }, [databaseId, setValue, user])

  const debouncedSave = useMemo(
    () =>
      debounce(async value => {
        let validUser: UserType = user === null ? emptyUser : user

        const data = {
          token: validUser.token,
          reflection_id: databaseId,
          response_text: value,
        }

        await axios('/api/reflection/update', { method: 'patch', data })

        setShowSavedIndicator(true)
        setTimeout(() => setShowSavedIndicator(false), 3000)
      }, DEBOUNCE_SAVE_DELAY_MS),
    [user, databaseId]
  )

  const handleFormEvent = useCallback(
    e => {
      debouncedSave(e.target.value)
    },
    [debouncedSave]
  )

  return (
    <div className={style.container}>
      <h3 className={style.title}>{title}</h3>
      {subtitle && <Markdown linkTarget="_blank" source={subtitle} escapeHtml={false} />}
      <div className={style.textContainer}>
        <textarea
          name="responseText"
          ref={register}
          className={style.textarea}
          placeholder={loading ? 'Loading...' : placeholder}
          disabled={loading}
          onKeyUp={handleFormEvent}
        />
        <div
          className={classNames(style.savingIndicator, {
            [style.savingIndicatorVisible]: showSavedIndicator,
          })}
        >
          <img src="/icons/checkmark-circle.svg" alt="saved" />
          <div className={style.savingText}>Response saved</div>
        </div>
      </div>
    </div>
  )
}

export { ReflectionBlock }
