import i18n from 'i18next'
import englishTranslation from '@lib/i18n/en-US/translation.json'
import spanishTranslation from '@lib/i18n/es/translation.json'
import { initReactI18next } from 'react-i18next'

export const resources = {
  'en-US': {
    translation: englishTranslation,
  },
  es: {
    translation: spanishTranslation,
  },
} as const

i18n.use(initReactI18next).init({
  lng: 'en-US',
  resources,
})
