import React, { useRef, useEffect } from 'react'
import * as typeformEmbed from '@typeform/embed'
import classNames from 'classnames'

import style from '@styles/TypeformEmbed.module.scss'

interface Props {
  typeformLink: string
  height: number
  onSubmit: (event: typeformEmbed.OnSubmitEvent) => void
}

const TypeformEmbed = ({ typeformLink, height, onSubmit }: Props) => {
  const typeformRef = useRef<HTMLDivElement>(null)
  const isTall = height >= 500

  useEffect(() => {
    if (!typeformRef.current) {
      return
    }

    typeformEmbed.makeWidget(typeformRef.current, typeformLink, {
      hideFooter: true,
      hideHeaders: true,
      opacity: 0,
      onSubmit: onSubmit,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeformRef, typeformLink, onSubmit])

  return (
    <div
      className={classNames(style.container, { [style.tall]: isTall }, { [style.short]: !isTall })}
    >
      <div ref={typeformRef} style={{ height, width: '100%' }}></div>
    </div>
  )
}

export { TypeformEmbed }
