import Cookies from 'universal-cookie'
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/dist/client/router'

import { getLocalizedPath } from '@lib/i18n'
import { UserType } from '@queries/types/user'

const useValidateUserToken = (locale: string) => {
  const [user, setUser] = useState<UserType | null>(null)

  const router = useRouter()

  const validateToken = useCallback(async () => {
    const cookies = new Cookies()
    const jti = cookies.get('jti')

    try {
      const validateResponse = await axios(`/api/users/validate?token=${jti}`, { method: 'get' })
      setUser(validateResponse.data)
    } catch (_) {
      router.push(getLocalizedPath(locale, '/sign-in'))
    }
  }, [locale, router])

  useEffect(() => {
    process.browser && validateToken()
  }, [router, locale, validateToken])

  return { user, refetchUser: validateToken }
}

export { useValidateUserToken }
