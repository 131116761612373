import React from 'react'
import Markdown from 'react-markdown'
import classNames from 'classnames'

import style from '@styles/TextBlock.module.scss'

interface Props {
  fields: {
    content: string
    layout?: string
    listIcon?: string
    listLayout?: string
    listSpacing?: string
  }
}

const TextBlock = ({ fields: { content, layout, listIcon, listLayout, listSpacing } }: Props) => {
  const layoutClass = (layout => {
    switch (layout) {
      case 'Centered':
        return style.centered
      default:
        return null
    }
  })(layout)

  const listIconClass = (icon => {
    switch (icon) {
      case 'Star':
        return style.star
      case 'Red X':
        return style.redX
      default:
        return null
    }
  })(listIcon)

  const listLayoutClass = (layout => {
    switch (layout) {
      case 'Two Columns':
        return style.twoColumnList
      case 'Three Columns':
        return style.threeColumnList
      default:
        return null
    }
  })(listLayout)

  const listSpacingClass = (listSpace => {
    switch (listSpace) {
      case 'Narrow':
        return style.narrowListSpacing
      default:
        return null
    }
  })(listSpacing)

  return (
    <Markdown
      className={classNames(
        style.content,
        layoutClass,
        listIconClass,
        listLayoutClass,
        listSpacingClass
      )}
      linkTarget="_blank"
      source={content}
      escapeHtml={false}
    />
  )
}

export { TextBlock }
