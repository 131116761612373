import React from 'react'
import classNames from 'classnames'
import { get } from 'lodash'

import style from '@styles/FlowBlock.module.scss'
import { MediaType } from '@contentful/types'

interface CircleText {
  fields: {
    title: string
    icon: MediaType
    backgroundColor: string
    description?: string
  }
}

interface Props {
  fields: {
    title: string
    steps: CircleText[]
  }
}

const FlowBlock = ({ fields: { title, steps } }: Props) => (
  <div className={style.container}>
    <h3 className={style.title}>{title}</h3>
    <div className={style.stepsContainer}>
      {steps.map((step, index) => (
        <Step key={index} fields={step.fields} />
      ))}
    </div>
  </div>
)

const Step = ({ fields: { title, icon, backgroundColor, description } }: CircleText) => {
  const backgroundColorClass = (color => {
    switch (color) {
      case 'Purple':
        return style.stepCirclePurple
      default:
        return null
    }
  })(backgroundColor)

  const iconUrl = get(icon, 'fields.file.url')
  const iconDescription = get(icon, 'fields.description')

  return (
    <div className={style.step}>
      <div className={classNames(style.stepCircle, backgroundColorClass)}>
        <div>
          <img src={iconUrl} alt={iconDescription} aria-hidden />
        </div>
        <div>{title}</div>
      </div>
      {description && <div className={style.stepDescription}>{description}</div>}
    </div>
  )
}

export { FlowBlock }
