import React from 'react'
import Markdown from 'react-markdown'

import style from '@styles/BasicHeader.module.scss'

interface Props {
  title: string
  subtitle: string
}

const BasicHeader = ({ title, subtitle }: Props) => (
  <div className={style.container}>
    <h1 className={style.title}>{title}</h1>
    <Markdown className={style.subtitle} linkTarget="_blank" source={subtitle} />
  </div>
)

export { BasicHeader }
