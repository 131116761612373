import axios from 'axios'
import { Url } from 'url'
import { useRouter } from 'next/dist/client/router'
import { useCallback, useEffect, useState } from 'react'

import { SectionType } from '@contentful/types'
import { isAhead } from '@contentful/page'
import { setProgressQuery } from '@queries/setProgressQuery'
import { UserType } from '@queries/types/user'

const useReplacePage = (sectionList: SectionType[]) => {
  const router = useRouter()

  const replacePage = useCallback(
    async (
      pageId: string,
      storedLocale: string,
      callback: () => unknown,
      urlOpts?: Partial<Url>
    ) => {
      router.events.on('routeChangeComplete', callback)

      const page = sectionList
        .map(section => section.fields.pages)
        .flat()
        .find(page => page.sys.id === pageId)

      if (!page) {
        callback()
        return
      }

      let pathname = page.fields.path.fields.path
      if (storedLocale && page.sys.locale !== storedLocale) {
        const contentfulPageResponse = await axios(
          `/api/contentful/page/${pageId}?locale=${storedLocale}`
        )
        pathname = contentfulPageResponse.data.path
      }

      router.replace({
        pathname,
        ...urlOpts,
      })
    },
    [router, sectionList]
  )

  return replacePage
}

interface NavigateToSavePointOnLoadOpts {
  currentPageId: string
  sectionList: SectionType[]
  onLoad: () => unknown
  storedProgress: UserType['progress']
  locale: string
  token?: string
}

const useNavigateToSavePointOnLoad = ({
  currentPageId,
  sectionList,
  onLoad,
  locale,
  storedProgress,
  token,
}: NavigateToSavePointOnLoadOpts) => {
  const router = useRouter()
  const [hasCheckedProgress, setHasCheckedProgress] = useState(false)
  const replacePage = useReplacePage(sectionList)

  useEffect(() => {
    if (!storedProgress) return

    const { locale: storedLocale, current_page_id: storedPageId } = storedProgress
    const isNewSavePoint = isAhead(currentPageId, storedPageId, sectionList)
    if (isNewSavePoint && token) {
      setProgressQuery({ current_page_id: currentPageId, token })
    }

    if (hasCheckedProgress) {
      return
    }

    // eslint-disable-next-line no-restricted-globals
    const params = new URLSearchParams(location.search)
    const isRestored = params.get('restored')

    const shouldNavigateToStoredPage =
      !isRestored && currentPageId !== storedPageId && !isNewSavePoint

    if (!!storedPageId && !!storedLocale && shouldNavigateToStoredPage) {
      replacePage(storedPageId, storedLocale, onLoad, { query: { restored: '1' } })
    } else {
      onLoad()
    }
    setHasCheckedProgress(true)
  }, [
    currentPageId,
    hasCheckedProgress,
    locale,
    onLoad,
    replacePage,
    router,
    sectionList,
    storedProgress,
    token,
  ])
}

export { useNavigateToSavePointOnLoad }
