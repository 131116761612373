import React from 'react'
import Markdown from 'react-markdown'

import style from '@styles/Footer.module.scss'
import { Link, LinkType } from 'components/Link'

interface Props {
  copyright: string
  links: LinkType[]
}

const Footer = ({ copyright, links }: Props) => (
  <div className={style.container}>
    <div className={style.content}>
      <div className={style.links}>
        {links.map(link => (
          <Link key={link.fields.name} fields={link.fields} />
        ))}
      </div>
      <Markdown
        className={style.copyright}
        linkTarget="_blank"
        source={copyright}
        escapeHtml={false}
      />
    </div>
  </div>
)

export { Footer }
