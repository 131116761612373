import React from 'react'
import Markdown from 'react-markdown'
import classNames from 'classnames'
import { get } from 'lodash'

import style from '@styles/CalloutBlock.module.scss'
import { MediaType } from '@contentful/types'

interface Props {
  fields: {
    title: string
    content: string
    contentTextAlignment?: string
    backgroundColor: string
    topLeftImage: MediaType
    bottomRightImage: MediaType
    spacing?: string
  }
}

const CalloutBlock = ({
  fields: {
    title,
    content,
    contentTextAlignment,
    backgroundColor,
    topLeftImage,
    bottomRightImage,
    spacing,
  },
}: Props) => {
  const contentTextAlignmentClass = (alignment => {
    switch (alignment) {
      case 'Left':
        return style.contentLeftTextAlignment
      default:
        return null
    }
  })(contentTextAlignment)

  const backgroundColorClass = (color => {
    switch (color) {
      case 'Purple':
        return style.containerPurple
      case 'Red':
        return style.containerRed
      case 'Teal':
        return style.containerTeal
      default:
        return null
    }
  })(backgroundColor)

  const spacingClass = (spacing => {
    switch (spacing) {
      case 'Top and No Bottom':
        return style.spacingTopAndNoBottom
      case 'Extra Bottom':
        return style.spacingExtraBottom
      default:
        return null
    }
  })(spacing)

  const topLeftImageUrl = get(topLeftImage, 'fields.file.url')
  const topLeftImageDescription = get(topLeftImage, 'fields.description')

  const bottomRightImageUrl = get(bottomRightImage, 'fields.file.url')
  const bottomRightImageDescription = get(bottomRightImage, 'fields.description')

  return (
    <div className={classNames(style.container, backgroundColorClass, spacingClass)}>
      <div className={style.contentContainer}>
        <div className={style.title}>{title}</div>
        <Markdown
          className={classNames(style.content, contentTextAlignmentClass)}
          linkTarget="_blank"
          source={content}
          escapeHtml={false}
        />
        <img
          className={style.topLeftImage}
          src={topLeftImageUrl}
          alt={topLeftImageDescription}
          aria-hidden
        />
        <img
          className={style.bottomRightImage}
          src={bottomRightImageUrl}
          alt={bottomRightImageDescription}
          aria-hidden
        />
      </div>
    </div>
  )
}

export { CalloutBlock }
