import React from 'react'

import style from '@styles/TopBar.module.scss'
import { LinkButton } from '@components/Button'
import { NavigationBarType } from '@contentful/types'

interface Props {
  nextPageUrl?: string | null
  title: string
  section: string | null
  navigationBarEntry: NavigationBarType
  nextButtonLabelOverride?: string | null
}

const TopBar = ({
  title,
  section,
  nextPageUrl,
  nextButtonLabelOverride,
  navigationBarEntry,
}: Props) => {
  const {
    fields: { adaptingVroomContent, trainingLabel, nextButtonLabel },
  } = navigationBarEntry

  const getPageTitle = () => {
    if (section === null) {
      return null
    }

    if (section === title) {
      return <span className={style.header}>{section}</span>
    }

    return (
      <>
        <span className={style.header}>{section}:</span> {title}
      </>
    )
  }

  return (
    <div className={style.container}>
      <p>
        <span className={style.header}>{trainingLabel}</span> {adaptingVroomContent}
      </p>
      <p>{getPageTitle()}</p>
      <div className={style.rightSide}>
        {nextPageUrl ? (
          <LinkButton passHref href={nextPageUrl}>
            {nextButtonLabelOverride ? (
              <>
                {nextButtonLabelOverride}
                <img
                  className={style.nextArrow}
                  src="/icons/right-arrow-white.svg"
                  aria-hidden
                  alt=""
                />
              </>
            ) : (
              nextButtonLabel
            )}
          </LinkButton>
        ) : null}
      </div>
    </div>
  )
}

export { TopBar }
