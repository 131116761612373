import React from 'react'
import classNames from 'classnames'
import { get } from 'lodash'

import style from '@styles/PhotoCard.module.scss'
import { Block } from '@components/Block'
import { BlockType, SectionType } from '@contentful/types'

interface Props {
  fields: {
    block: BlockType
    caption: string
    color: string
    title: string
  }
  section: SectionType
}

const PhotoCard = ({ fields: { block, caption, color, title }, section }: Props) => {
  const type = get(block, 'sys.contentType.sys.id')

  const colorClass = (color => {
    switch (color) {
      case 'Green':
        return style.contentGreen
      case 'Red':
        return style.contentRed
      default:
        return null
    }
  })(color)

  return (
    <div className={classNames(style.container, colorClass)}>
      <div className={style.title}>{title}</div>
      <Block type={type as any} fields={block.fields} section={section} />
      <p className={style.caption}>{caption}</p>
    </div>
  )
}

export { PhotoCard }
