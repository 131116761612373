import React from 'react'
import classnames from 'classnames'
import styles from '@styles/EmbeddedContent.module.scss'

interface Props {
  fields: {
    embedCode: string
    size: string
    spacing: string
  }
}

export const EmbeddedContent = ({ fields: { embedCode, size, spacing } }: Props) => {
  const spacingClass = (spacing => {
    switch (spacing) {
      case 'Top and bottom':
        return styles.spacingTopAndBottom
      case 'None':
        return styles.spacingNone
      default:
        return null
    }
  })(spacing)

  return (
    <div className={classnames(styles.container, spacingClass)}>
      <div
        className={classnames([
          {
            [styles.small]: size === 'Small',
            [styles.large]: size === 'Large',
          },
        ])}
        dangerouslySetInnerHTML={{
          __html: embedCode,
        }}
      />
    </div>
  )
}
